import React, { ComponentType, ReactElement, useContext } from 'react';
import t from '../locales/fi';
import { PageContext } from '../common/context';
import {
  InvisiblePhase, Phase, ProgressPhase,
} from '../common/enums';

const ball = (isActive: boolean, phase: string) => (
  <div key={phase} className="ball-container">
    <span className={`ball ${isActive ? 'active' : 'inactive'}`} />
    <span className="ball-title">{t[phase]}</span>
  </div>
);

const renderPhaseAs = (phase: string) => {
  switch (phase) {
    case InvisiblePhase.PRE_CHECK:
    case InvisiblePhase.HANDLE_ORDER:
      return Phase.CONTACT_AND_PAYMENT;
    default:
      return Phase[phase as InvisiblePhase | ProgressPhase];
  }
};

const collectBarItems = (currentPhase: ProgressPhase | InvisiblePhase): Array<JSX.Element> => {
  const phaseMap: { [key: string]: JSX.Element } = {};
  Object.keys(Phase).forEach((phase) => {
    const visualPhase = renderPhaseAs(phase);
    const isSelected = visualPhase === renderPhaseAs(currentPhase);
    phaseMap[visualPhase] = ball(isSelected, visualPhase);
  });
  return Object.values(phaseMap);
};

const ProgressBar: ComponentType = (): ReactElement => {
  const { phase } = useContext(PageContext);
  return (
    <div className="progress-bar-container">
      <hr className="ball-line" />
      {collectBarItems(phase)}
    </div>
  );
};

export default ProgressBar;
