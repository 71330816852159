/* eslint-disable react/require-default-props */
import { ChangeEventHandler, ComponentType, ReactElement } from 'react';

type RadioButtonProps = {
  id: string;
  label: string | ReactElement;
  name: string;
  checked?: boolean;
  onChange?: ChangeEventHandler<any>;
  disabled?: boolean;
};

const PaymentRadioButton: ComponentType<RadioButtonProps> = ({
  id,
  label,
  name,
  checked,
  onChange,
  disabled,
}: RadioButtonProps): ReactElement => (
  <div className="radio-pair">
    <input name={name} checked={checked} disabled={disabled} type="radio" id={id} onChange={onChange} />
    <label className="radio-label" htmlFor={id}>{label}</label>
  </div>
);

export default PaymentRadioButton;
