/* eslint-disable no-underscore-dangle */
import React, {
  ComponentType, Dispatch,
  ReactElement, SetStateAction,
} from 'react';
import t from '../../locales/fi';
import Input from '../Input';
import { FieldValidationErrors } from '../../common/form/utils';
import { OrderContactForm } from '../../common/models/types';
import { paymentFormSchema } from '../../common/form/schemas';

interface OrderContactProps {
  fieldValidationErrors: FieldValidationErrors;
  setFieldValidationErrors: (fieldValidationErrors: FieldValidationErrors) => void;
  formData: OrderContactForm;
  setFormData: Dispatch<SetStateAction<OrderContactForm>>
}

const OrderContact: ComponentType<OrderContactProps> = ({
  fieldValidationErrors, setFieldValidationErrors, formData,
  setFormData,
}: OrderContactProps): ReactElement => {
  const clearErrors = (event: React.ChangeEvent<HTMLInputElement>) => {
    const oldErrors = fieldValidationErrors;
    if (oldErrors[event.target.name]) {
      const newErrors: FieldValidationErrors = {
        ...oldErrors,
      };
      delete newErrors[event.target.name];
      setFieldValidationErrors(newErrors);
    }
  };

  const updateFormData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...formData,
      [event.target.name]: event.target.value,
    };
    setFormData(newFormData);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateFormData(event);
    clearErrors(event);
  };

  const {
    phone, address, zip, postal, firstName, lastName, email,
  } = formData;

  return (
    <form className="input-form">
      <Input
        inputClassName="order-information-input"
        label={t.FIRST_NAME}
        name="firstName"
        type="text"
        id="firstName"
        placeholder={t.FIRST_NAME}
        value={firstName}
        onChange={onInputChange}
        errorMessage={fieldValidationErrors?.firstName}
        required={paymentFormSchema.firstName._flags.presence === 'required'}
      />
      <Input
        inputClassName="order-information-input"
        label={t.LAST_NAME}
        name="lastName"
        type="text"
        id="lastName"
        placeholder={t.LAST_NAME}
        value={lastName}
        onChange={onInputChange}
        errorMessage={fieldValidationErrors?.lastName}
        required={paymentFormSchema.lastName._flags.presence === 'required'}
      />
      <div className="address-input-wrapper">
        <Input
          inputClassName="address-input"
          value={address}
          onChange={onInputChange}
          name="address"
          id="address"
          type="text"
          placeholder={t.ADDRESS}
          label={t.ADDRESS}
          errorMessage={fieldValidationErrors?.address}
          required={paymentFormSchema.address._flags.presence === 'required'}
        />
      </div>
      <Input
        inputClassName="order-information-input"
        value={zip}
        onChange={onInputChange}
        name="zip"
        id="zip"
        type="number"
        placeholder={t.ZIP}
        label={t.ZIP}
        errorMessage={fieldValidationErrors?.zip}
        required={paymentFormSchema.zip._flags.presence === 'required'}
      />
      <Input
        inputClassName="order-information-input"
        value={postal}
        onChange={onInputChange}
        name="postal"
        id="postal"
        type="text"
        placeholder={t.POSTAL}
        label={t.POSTAL}
        errorMessage={fieldValidationErrors?.postal}
        required={paymentFormSchema.postal._flags.presence === 'required'}
      />
      <Input
        inputClassName="order-information-input"
        value={phone}
        onChange={onInputChange}
        name="phone"
        id="phone"
        type="number"
        placeholder={t.PHONE}
        label={t.PHONE}
        errorMessage={fieldValidationErrors?.phone}
        required={paymentFormSchema.phone._flags.presence === 'required'}
      />
      <Input
        inputClassName="order-information-input"
        value={email}
        onChange={onInputChange}
        name="email"
        id="email"
        type="text"
        placeholder={t.EMAIL}
        label={t.EMAIL}
        errorMessage={fieldValidationErrors?.email}
        required={paymentFormSchema.email._flags.presence === 'required'}
      />
    </form>
  );
};

export default OrderContact;
