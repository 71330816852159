/* eslint-disable react/require-default-props */
import React, { ComponentType, ReactElement } from 'react';

type LinkProps = {
  href: string;
  children: React.ReactNode;
  customClass?: string;
};

const Link: ComponentType<LinkProps> = ({
  href,
  children,
  customClass,
}: LinkProps): ReactElement => (
  <a href={href} className={customClass} target="_blank" rel="noreferrer">
    {children}
  </a>
);

export default Link;
