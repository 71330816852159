import {
  ComponentType,
  useMemo,
  useState,
} from 'react';
import { PageContext } from '../context';
import {
  useOfferHook, usePhaseHook, usePaywallSessionHook,
} from '../hooks';
import { Order } from '../models/Order';
import { GenericProviderProps, PaywallError } from '../models/types';

const PageProvider: ComponentType<GenericProviderProps> = ({ children }: GenericProviderProps) => {
  const [offer, setOffer] = useOfferHook();
  const [phase, setPhase] = usePhaseHook();
  const [error, setError] = useState<PaywallError>();
  const [order, setOrder] = useState<Order>();
  const [session, setSession] = usePaywallSessionHook();

  const value = useMemo(() => ({
    error, setError, offer, setOffer, phase, setPhase, setOrder, setSession, session,
  }), [error, phase, offer, order, session]);

  return (
    <PageContext.Provider value={value}>
      {children}
    </PageContext.Provider>
  );
};

export default PageProvider;
