import {
  ComponentType,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ToasterContext } from '../context';
import { useToasterHook } from '../hooks';
import { GenericProviderProps } from '../models/types';

const ToasterProvider: ComponentType<GenericProviderProps> = ({ children }: GenericProviderProps) => {
  const [content, visible, showToaster, hideToaster] = useToasterHook();
  const [toasterVisible, setToasterVisible] = useState(false);

  const value = useMemo(
    () => ({
      content, toasterVisible, visible, showToaster, hideToaster,
    }),
    [content, visible, toasterVisible],
  );

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        // wait for toaster element to render
        setToasterVisible(true);
      }, 500);
    } else {
      setTimeout(() => {
        // wait for fade out animation to finish
        setToasterVisible(false);
      }, 500);
    }
  }, [visible]);

  return (
    <ToasterContext.Provider value={value}>
      {children}
    </ToasterContext.Provider>
  );
};

export default ToasterProvider;
