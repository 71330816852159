import {
  ComponentType,
  useEffect,
  useMemo,
} from 'react';
import { TABLET_MIN_WIDTH } from '../constants';
import { DisplayContext } from '../context';
import { DisplayMode } from '../enums';
import { useDisplayModeHook } from '../hooks';
import { GenericProviderProps } from '../models/types';

const DisplayProvider: ComponentType<GenericProviderProps> = ({ children }: GenericProviderProps) => {
  const [displayMode, setDisplayMode] = useDisplayModeHook();

  useEffect(() => {
    setInterval(
      () => setDisplayMode(
        window.innerWidth >= TABLET_MIN_WIDTH
          ? DisplayMode.DESKTOP
          : DisplayMode.MOBILE,
      ),
      200,
    );
  }, [displayMode]);

  const value = useMemo(
    () => ({
      displayMode,
      setDisplayMode,
    }),
    [displayMode],
  );

  return (
    <DisplayContext.Provider value={value}>
      {children}
    </DisplayContext.Provider>
  );
};

export default DisplayProvider;
