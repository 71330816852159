import {
  ComponentType,
  useMemo,
  useReducer,
} from 'react';
import { OrderHandlingReducerContext } from '../context';
import { GenericProviderProps } from '../models/types';
import orderHandlingReducer from '../reducers/order';

const OrderHandlingProvider: ComponentType<GenericProviderProps> = ({ children }: GenericProviderProps) => {
  const [state, dispatch] = useReducer(orderHandlingReducer, {});

  const value = useMemo(
    () => ({
      state, dispatch,
    }),
    [state],
  );

  return (
    <OrderHandlingReducerContext.Provider value={value}>
      {children}
    </OrderHandlingReducerContext.Provider>
  );
};

export default OrderHandlingProvider;
