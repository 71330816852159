/* eslint-disable react/no-danger,no-underscore-dangle */
import React, { ComponentType, ReactElement, useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { DisplayMode } from '../../common/enums';
import { ObjectWithHtmlProperty } from '../../common/models/Offer';
import t from '../../locales/fi';

interface PopupWindowProps {
  name: string;
  image: string;
  text: ObjectWithHtmlProperty;
  extraText: ObjectWithHtmlProperty;
  displayMode: DisplayMode;
}

const PopupWindow: ComponentType<PopupWindowProps> = ({
  name, image, text, extraText, displayMode,
}: PopupWindowProps): ReactElement => {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  return (
    <>
      <button
        className="read-more-button"
        type="button"
        onClick={() => setOpen(true)}
      >
        {t.READ_MORE}
      </button>
      <Popup
        open={open}
        closeOnDocumentClick
        onClose={closeModal}
      >
        <button className="close" type="button" onClick={closeModal}>
          &times;
        </button>
        <div className="popup-window">
          {displayMode === DisplayMode.DESKTOP && <img className="popup-window-img" alt="mag" src={image} />}
          <div>
            <h1>{name}</h1>
            <span dangerouslySetInnerHTML={{ __html: text.__html }} />
            <span dangerouslySetInnerHTML={{ __html: extraText.__html }} />
          </div>
        </div>
      </Popup>
    </>

  );
};
export default PopupWindow;
