import React, {
  ComponentType, Dispatch, ReactElement, SetStateAction, useContext, useEffect, useState,
} from 'react';
import Joi from 'joi';
import { Offer } from '../common/models/Offer';
import { PaymentMethod, Phase } from '../common/enums';
import OrderInfo from '../components/ContactAndPayment/OrderInfo';
import OrderContact from '../components/ContactAndPayment/OrderContact';
import PaymentSelection from '../components/ContactAndPayment/PaymentSelection';
import { INIT_HANDLE_ORDER } from '../common/actions/types/order';
import { User } from '../common/models/User';
import t from '../locales/fi';
import {
  initializeRawOrder,
  OfferData,
  orderOfferDataFromOffer,
  orderRoleDataFromUser,
  Payer,
  RawOrder,
  setRawOrderProperties,
} from '../common/models/RawOrder';
import {
  OrderContext, OrderHandlingReducerContext, PageContext, AuthContext,
} from '../common/context';
import Spinner from '../components/Spinner';
import { FieldValidationErrors, validateFormData } from '../common/form/utils';
import {
  formFromUser, getUpdatedUserIfHasChanged,
} from '../common/utils/commonUtils';
import { paymentFormSchema } from '../common/form/schemas';
import Item from '../common/models/gtm/Item';
import { buildAndPushGtmEventObj, GtmEvent } from '../common/utils/gtm';
import { getMagazineCategoryFromPackageId } from '../common/utils/offerUtils';
import { PaywallSession } from '../common/models/Session';

type PaymentAndContactProps = {
  offer: Offer;
  paymentMethod: PaymentMethod | undefined;
  setPaymentMethod: Dispatch<SetStateAction<PaymentMethod>>;
};

const buildOrder = ({
  articleURL,
  user,
  offer,
  paymentMethod,
  session,
}: {
  articleURL: string | undefined,
  user: User;
  offer: Offer;
  paymentMethod: string;
  session: PaywallSession;
}): RawOrder => {
  const order = initializeRawOrder();
  const roleData: Payer = orderRoleDataFromUser(user);
  const offerData: OfferData = orderOfferDataFromOffer(offer);

  const {
    id: referrerSid,
    paywallSessionId,
    sourceUrl,
    referrerUrl,
    consentGiven,
    params: {
      articleURL: articleUrlFromParams,
    } = { articleURL: null },
  } = session as any;

  const baseOrder = {
    ...order,
    articleURL: articleURL || articleUrlFromParams,
    paymentMethod,
    ...roleData,
    ...offerData,
  };
  const consentRequiredData = {
    sourceSid: paywallSessionId,
    referrerUrl,
    referrerSid,
    sourceUrl,
  };
  return consentGiven ? { ...baseOrder, ...consentRequiredData } : baseOrder;
};

const ContactAndPayment: ComponentType<PaymentAndContactProps> = ({
  offer, paymentMethod, setPaymentMethod,
}: PaymentAndContactProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const { user, setUser } = useContext(AuthContext);
  const { rawOrder, setRawOrder } = useContext(OrderContext);
  const { setPhase, session } = useContext(PageContext);
  const { dispatch } = useContext(OrderHandlingReducerContext);
  // PaymentSelection.tsx Props
  const [termsAccepted, setTermsAccepted] = useState(false);

  // OrderContact.tsx Props
  const [fieldValidationErrors, setFieldValidationErrors] = useState({} as FieldValidationErrors);
  const [formData, setFormData] = useState(formFromUser(user as User));
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 250);
  }, []);

  useEffect(() => {
    if (offer.magazines?.[0]) {
      const {
        packageId,
        name,
        offerPrice,
        salesType,
      } = offer.magazines[0];

      const item: Item[] = [{
        item_id: packageId,
        item_name: name,
        quantity: offer.magazines.length,
        currency: 'EUR',
        price: Number(typeof offerPrice === 'number' ? offerPrice : offerPrice.replace(',', '.')),
        item_brand: offer.brandsOnOffering[0],
        item_category: getMagazineCategoryFromPackageId(packageId),
        item_variant: salesType,
        affiliation: 'maksumuuri',
        item_list_id: `${offer.brandsOnOffering[0]}-paywall-placeholder`,
        item_list_name: `${offer.brandsOnOffering[0]}-maksumuuri`,
        index: 1,
      }];
      buildAndPushGtmEventObj({ event: GtmEvent.BEGIN_CHECKOUT, gtmObj: item });
    }
  }, []);

  const submit = () => {
    const {
      firstName, lastName,
      phone, address, zip, postal, email,
    } = formData;
    setRawOrderProperties(rawOrder!, {
      payerFirstName: firstName,
      payerLastName: lastName,
      payerPhoneNumber: phone,
      payerStreetAddress: address,
      payerZipCode: zip,
      payerCity: postal,
      payerEmail: email,
    });
    setRawOrder(rawOrder!);
    const updatedUser = getUpdatedUserIfHasChanged(
      {
        firstName,
        lastName,
        address: { streetName: address, zipCode: zip, city: postal },
        contactInfo: { phoneNumber: phone, email },
      },
      user,
    );
    if (updatedUser) {
      setUser(updatedUser);
    }
    const order = buildOrder({
      articleURL: rawOrder?.articleURL,
      user: (updatedUser || user) as User,
      offer: offer as Offer,
      paymentMethod: paymentMethod!,
      session: session!,
    });
    setRawOrder(order);
    dispatch({ paymentMethod, type: INIT_HANDLE_ORDER });
    setPhase(Phase.HANDLE_ORDER);
  };

  const onSubmit = () => {
    const joiSchema = Joi.object(paymentFormSchema).options({ abortEarly: false });
    const validationErrors: FieldValidationErrors
    | undefined = validateFormData(formData, joiSchema);
    if (validationErrors) {
      setFieldValidationErrors(validationErrors);
      return;
    }
    submit();
  };

  const submitAllowed = termsAccepted
        && (paymentMethod && (!fieldValidationErrors
            || Object.values(fieldValidationErrors)?.length === 0));

  if (isLoading) return <Spinner />;

  return (
    <>
      <OrderInfo
        offer={offer}
      />
      <OrderContact
        fieldValidationErrors={fieldValidationErrors}
        setFieldValidationErrors={setFieldValidationErrors}
        formData={formData}
        setFormData={setFormData}
      />
      <PaymentSelection
        offer={offer}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        termsAccepted={termsAccepted}
        setTermsAccepted={setTermsAccepted}
      />
      <div>
        <button
          className={`submit-button ${submitAllowed ? 'active-button' : ''}`}
          type="button"
          onClick={onSubmit}
          disabled={!submitAllowed}
        >
          {t.ORDER}
        </button>
      </div>
    </>
  );
};

export default ContactAndPayment;
