import { PaymentMethod, EnabledPaymentMethod } from '../enums';
import { Offer } from '../models/Offer';

/**
 * Get magazine full name in genetive from by package id
 * @param {string} brand 2 character string (TM, SK)
 * @returns {string} full name
 */
export const magazineFullNameGenitiveByPackageId = (brand: string) => {
  switch (brand) {
    case 'TM':
      return 'Tekniikan Maailman';
    case 'SK':
      return 'Suomen Kuvalehden';
    default:
      return 'lehden';
  }
};

export const hasExistingSubscription = (subscriptions: Array<object>, offer: Offer) => {
  const { packageId: offerPackageId } = offer.magazines![0];
  return !!subscriptions.find(
    ({ packageId: subscriptionPackageId }: any) => subscriptionPackageId === offerPackageId,
  );
};

export const queryParamsFromOffer = (offer: Offer) => {
  const { offeringId, magazines } = offer;
  const { id: offerId } = magazines?.[0] || {};
  return new URLSearchParams({ offeringId, offerId } as any);
};

/**
 * Calculate initial payment method for order form.
 * No choices should be chosen by default, hence undefined is returned if
 * more than one is available.
 */
export const getDefaultPaymentMethod = (offer: Offer): PaymentMethod | undefined => {
  if (!offer?.magazines) {
    return PaymentMethod.INVOICE;
  }
  const {
    enabledPaymentMethods,
  } = offer!.payment;

  if (enabledPaymentMethods.length > 1) return undefined;

  const paymentMethod = enabledPaymentMethods[0];
  if (paymentMethod === EnabledPaymentMethod.CARD) {
    return PaymentMethod.CARD;
  }
  return PaymentMethod.INVOICE;
};

export const getMagazineCategoryFromPackageId = (packageId: string):string => {
  let result = 'MUUT';
  if (packageId.includes('PRINTTI')) {
    result = 'lehti';
  } else if (packageId.includes('DIGI')) {
    result = 'digi';
  } else if (packageId.includes('COMBO')) {
    result = 'combo';
  }
  return result;
};
