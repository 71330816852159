/* eslint-disable max-len */
/**
 * It is used to determine the current phase of the order process.
 * Change of the phase is done by calling the setPhase function.
 * There are logical consequences of changing the phase like rendering different components.
 * The phase is also used to determine the order of the steps in the progress bar
 * and to track current position in the progress bar.
 */
export enum ProgressPhase {
  LOGIN = 'LOGIN',
  CONTACT_AND_PAYMENT = 'CONTACT_AND_PAYMENT',
  SUMMARY = 'SUMMARY',
}

export enum InvisiblePhase {
  PRE_CHECK = 'PRE_CHECK',
  HANDLE_ORDER = 'HANDLE_ORDER',
}

export const Phase = { ...ProgressPhase, ...InvisiblePhase };

export enum PaymentMethod {
  INVOICE = 'lasku',
  CARD = 'korttimaksu',
}

export enum DisplayMode {
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
  DESKTOP = 'DESKTOP',
}

export enum EnabledPaymentMethod {
  CARD = 'CREDIT_CARD',
  INVOICE = 'INVOICE',
}
