/* eslint-disable consistent-return */
/* eslint-disable max-len */
import {
  useCallback, useEffect, useMemo, useReducer, useRef,
} from 'react';
import { BACKEND_ENDPOINT } from './constants';
import { ApiPayload, QueryParams } from './models/types';

const HEADERS = {
  Accept: 'application/json',
  Pragma: 'no-cache',
  'Content-Type': 'application/json',
  'x-api-key': '',
};

type RequestMethod = 'POST' | 'GET' | 'DELETE' | 'PUT';
type DataReducerState<T> = { data: T, status?: number } & AsyncState & { dataInitialized: boolean };
type DataStateAction<T> = { type: 'SET_DATA', payload: T }
| { type: 'SET_DATA_WITH_STATUS', payload: { data: T, status: number } }
| { type: 'UPDATE_DATA', payload: Partial<T> }
| { type: 'FETCH' }
| { type: 'ERROR', payload: { message: string, status?: number, extra?: string } }
| { type: 'UPDATE_DATA_CUSTOM', payload: (prevState: T) => T };

type AsyncState = {
  loading: boolean;
  error?: Error;
  initialState?: any;
};

type UseApiRequestMeta = {
  headers?: RequestInit['headers'];
  endpoint?: string;
  _silent?: boolean;
};

type UseApiInput<T> = {
  data: T;
  loading?: boolean;
  error?: any;
  status?: number;
  debounce?: number;
};

type Post<T> = (url: string, payload?: UseApiRequestMeta & { body?: any }) => Promise<T>;
type Get<T> = (url: string, payload?: UseApiRequestMeta & { queryParams?: QueryParams }) => Promise<T>;

type UseApiOutput<T> = {
  post: Post<T>;
  get: Get<T>;
  dispatch: (action: DataStateAction<T>) => void;
  cancelRequest(): void;
  catch(error: any): void;
} & DataReducerState<T>;

/**
 * Resolve endpoint, url and query params to an url
 * @param {string} url
 * @param {QueryParams} queryParams
 * @param {string} endpoint
 * @returns {string}
 */
const resolveUrl = (url: string, queryParams: QueryParams, endpoint: string): string => {
  let resolvedUrl = `${endpoint}/${url}`;
  if (queryParams) {
    const resolvedQueryParams: URLSearchParams = new URLSearchParams(queryParams as any);
    resolvedUrl = `${resolvedUrl}?${resolvedQueryParams}`;
  }
  return resolvedUrl;
};

// promise that never resolves
const neverResolve = new Promise<void>(() => { });

/**
 *
 * @param {string} url requested url
 * @param {object<ApiPayload>} payload Object containing method, body, queryparams, headers and endpoint
 * @returns {object<Promise<T, number>>} Promise with data and statuscode
 */
export async function api<T = undefined>(
  url: string,
  payload?: ApiPayload,
): Promise<{ data: T, status: number }> {
  const {
    body, queryParams, customHeaders, method = 'GET', customEndpoint, ...rest
  } = payload || {} as any;
  const newHeaders = { ...HEADERS, ...customHeaders };
  const resolvedUrl = resolveUrl(url, queryParams, customEndpoint || BACKEND_ENDPOINT);
  const result = await fetch(resolvedUrl, {
    ...rest,
    body,
    method,
    headers: newHeaders,
    credentials: 'include',
  });
  const { status } = result;
  const out: any = { status, data: undefined };
  if (status === 204 || status === 202) return out;
  // eslint-disable-next-line no-throw-literal
  // eslint-disable-next-line @typescript-eslint/no-throw-literal
  if (status === 404 || status === 405 || status === 413) throw { status, message: await result.text() };
  const data: JSON = await result.json();
  // eslint-disable-next-line no-throw-literal
  if (result.status >= 400) throw { ...out, ...data };
  out.data = data;
  return out;
}

// util for useApi hook, for managing 'loading', 'error' and 'data' state
function useDataStateReduce<T>(initialState: DataReducerState<T>) {
  const [state, dispatch] = useReducer((prevState: any, action: DataStateAction<T>) => {
    switch (action.type) {
      case 'FETCH': {
        if (prevState.loading && prevState.error === undefined) return prevState;
        return { ...prevState, loading: true, error: undefined };
      }
      case 'SET_DATA': return {
        ...prevState, loading: false, data: action.payload, dataInitialized: true,
      };
      case 'SET_DATA_WITH_STATUS': {
        const { data, status } = action.payload;
        return {
          ...prevState, status, data, loading: false, dataInitialized: true,
        };
      }
      case 'ERROR': {
        const { message, status, extra } = action.payload;
        return {
          ...prevState, loading: false, error: { message, status, extra }, status,
        };
      }
      case 'UPDATE_DATA': return { ...prevState, data: { ...prevState.data, ...action.payload } };
      case 'UPDATE_DATA_CUSTOM': return { ...prevState, data: action.payload(prevState.data) };
      default:
          // Do nothing
    }
  }, initialState);
  return [state, dispatch];
}

export function useApi<T = any>(initialData: T, { loading = false, error }: Omit<UseApiInput<T>, 'data'> = {}, { headers, endpoint }: { headers?: HeadersInit, endpoint?: string } = {}): UseApiOutput<T> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [state, _dispatch] = useDataStateReduce({
    data: initialData, loading, error, dataInitialized: false,
  });
  const mounted = useRef(true);
  const dispatch: typeof _dispatch = useCallback((action: Function) => {
    if (mounted.current) return _dispatch(action);
  }, []);
  const cancelPending = useRef(() => { });
  const methods = useMemo(() => {
    const onLoadSuccess = (result: { data: T, status: number }) => {
      dispatch({ type: 'SET_DATA_WITH_STATUS', payload: result });
    };
    const onLoadError = (err: any) => {
      if (err.statusCode === 403) {
        window.location.href = '/login';
      }
      if (err) {
        dispatch({ type: 'ERROR', payload: err });
      }
      return neverResolve;
    };
    const doFetch = (url: string, {
      method = 'GET', body, queryParams, _silent,
    }: { method?: RequestMethod, body?: any, queryParams?: QueryParams } & UseApiRequestMeta): Promise<void> => new Promise((res) => {
      const promise = api<T>(url, {
        method, body, queryParams, customHeaders: headers, customEndpoint: endpoint,
      });
      if (_silent) return;
      dispatch({ type: 'FETCH' });
      res(promise
        .then(onLoadSuccess)
        .catch(onLoadError));
    });

    return {
      get(url: string, meta?: { queryParams?: QueryParams } & UseApiRequestMeta) {
        return doFetch(url, {
          ...meta, method: 'GET',
        });
      },
      post(url: string, meta?: { body?: any } & UseApiRequestMeta) {
        return doFetch(url, {
          ...meta, method: 'POST',
        });
      },
      put(url: string, meta?: { body?: any } & UseApiRequestMeta) {
        return doFetch(url, {
          ...meta, method: 'PUT',
        });
      },
      catch(err: any) {
        onLoadError(err);
      },
    };
  }, [dispatch]);
  useEffect(() => () => {
    cancelPending.current();
    mounted.current = false;
  }, []);
  const cancelRequest = useCallback(() => {
    cancelPending.current();
  }, []);
  return useMemo(() => ({
    ...methods, ...state, dispatch, cancelRequest,
  }), [dispatch, methods, state]) as any;
}
