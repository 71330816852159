/*
 * Order action types
 */
export const ORDER_HANDLING_START = 'ORDER_HANDLING_START';
export const INIT_HANDLE_ORDER = 'INIT_HANDLE_ORDER';
export const INIT_CARD_PAYMENT_ORDER = 'INIT_CARD_PAYMENT_ORDER';
export const INIT_INVOICE_PAYMENT_ORDER = 'INIT_INVOICE_PAYMENT_ORDER';
export const ORDER_INIT_SUCCESS = 'ORDER_INIT_SUCCESS';
export const ORDER_INVOICE_POST_INIT = 'ORDER_INVOICE_POST_INIT';
export const ORDER_INVOICE_POST_INIT_FAILURE = 'ORDER_INVOICE_POST_INIT_FAILURE';
export const ORDER_ADD_CARD = 'ORDER_ADD_CARD';
export const ORDER_ADD_CARD_FAILURE = 'ORDER_ADD_CARD_FAILURE';
export const ORDER_CANCEL_SUCCESS = 'ORDER_CANCEL_SUCCESS';
export const ORDER_CANCEL_FAILURE = 'ORDER_CANCEL_FAILURE';
export const ORDER_CHARGE_CARD_FAILURE = 'ORDER_CHARGE_CARD_FAILURE';
export const ORDER_SUBSCRIBE = 'ORDER_SUBSCRIBE';
export const ORDER_SUBSCRIBE_FAILURE = 'ORDER_SUBSCRIBE_FAILED';
export const ORDER_SET_CUSTOMER = 'ORDER_SET_CUSTOMER';
export const ORDER_COMPLETE = 'ORDER_COMPLETE';
export const ORDER_INIT_SUBSCRIBE = 'ORDER_INIT_SUBSCRIBE';
