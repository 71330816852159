import { Storage } from './models/types';

let siteStore: any = {};
let sessionEndTime: number = 0;
let sessionEndCallback: any;
let sessionEndTimeoutHandle: any;
const SESSION_DURATION: number = 1800; // 30 minutes in seconds
const SESSION_TIME_LEFT_POPUP: number = 60;

export default {
  setStore({ key, value, storageType = Storage.SESSION }:
  { key: string, value: any, storageType: Storage }) {
    const data = typeof value !== 'string' ? JSON.stringify(value) : value;
    if (storageType === Storage.SESSION) {
      sessionStorage.setItem(key, data);
    } else if (storageType === Storage.LOCAL) {
      localStorage.setItem(key, data);
    } else {
      siteStore[key] = data;
    }
  },
  getStore({ key, defaultValue, storageType = Storage.SESSION }:
  { key: string, defaultValue?: any, storageType: Storage }) {
    let data;
    if (storageType === Storage.SESSION) {
      data = sessionStorage.getItem(key);
    } else if (storageType === Storage.LOCAL) {
      data = localStorage.getItem(key);
    } else {
      data = siteStore[key];
    }
    if (data != null && data !== 'undefined') return JSON.parse(data);
    if (defaultValue) return defaultValue;
    return null;
  },
  removeStore(key: string, storageType = Storage.SESSION) {
    if (storageType === Storage.SESSION) {
      sessionStorage.removeItem(key);
    } else if (storageType === Storage.LOCAL) {
      localStorage.removeItem(key);
    } else {
      delete siteStore[key];
    }
  },
  clearStore({ includeOnly = [], useSessionStorage = true, useLocalStorage = true } = {}) {
    if (includeOnly.length > 0) {
      includeOnly.forEach((key) => {
        if (useSessionStorage) sessionStorage.removeItem(key);
        if (useLocalStorage) localStorage.removeItem(key);
      });
      return;
    }
    if (useLocalStorage) localStorage.clear();
    if (useSessionStorage) sessionStorage.clear();
    else siteStore = {};
  },
  registerSessionEndCallback(callback: any) {
    sessionEndCallback = callback;
  },
  refreshSession() {
    if (sessionEndCallback) {
      if (sessionEndTimeoutHandle) window.clearTimeout(sessionEndTimeoutHandle);
      sessionEndTimeoutHandle = window.setTimeout(
        // eslint-disable-next-line @typescript-eslint/no-implied-eval
        sessionEndCallback,
        (SESSION_DURATION - SESSION_TIME_LEFT_POPUP) * 1000,
      );
      sessionEndTime = Date.now() / 1000 + SESSION_DURATION;
    }
  },
  clearSession() {
    window.clearTimeout(sessionEndTimeoutHandle);
    sessionEndTime = 0;
  },
  getSessionEnd() {
    return sessionEndTime;
  },
};
