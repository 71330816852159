import { ComponentType, ReactElement } from 'react';
import { logoByPackageId } from '../common/utils/logoUtils';

type BannerLogoProps = {
  packageId: string | undefined
};

const BannerLogo: ComponentType<BannerLogoProps> = ({
  packageId,
}: BannerLogoProps): ReactElement => (
  <img alt="logo" className="banner-img" src={logoByPackageId(packageId)} />
);

export default BannerLogo;
