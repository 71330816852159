/* eslint-disable max-len */
const t: { [key: string]: string } = {
  // Please add in alphabetical order
  ADDING_CARD_FAILED: 'Kortin lisäys epäonnistui.',
  ADDRESS: 'Osoite',
  ADDRESS_EMPTY: 'Syötä osoite',
  CAN_GO_TO_ARTICLE: 'Voit nyt siirtyä lukemaan palvelua.',
  CARD_ADDED_SUCCESFULLY: 'Maksukortin lisäys onnistui',
  CARD_CHARGE_FAILED: 'Kortin käsittely epäonnistui',
  CARD_PAYMENT: 'Korttimaksu',
  CHARGING_CARD_FAILED: 'Kortin veloitus epäonnistui.',
  CONFIRM_INFORMATION: 'Vahvista tilaajatiedot',
  CONTACT_AND_PAYMENT: 'Yhteystiedot ja maksu',
  CONTACT_US: 'Ota yhteyttä ',
  CONTINUE: 'Jatka',
  CUSTOMER_SERVICE: 'asiakaspalveluun',
  DEFAULT_SITE_TITLE: 'Otavamedia',
  EMAIL: 'Sähköposti',
  EMAIL_EMPTY: 'Syötä sähköposti',
  EMAIL_INVALID: 'Sähköposti on virheellinen',
  ERROR_PERSON_NOT_FOUND:
    'Henkilöä ei löytynyt annetulla sähköpostiosoitteella.',
  FROM_HERE: 'tästä',
  FIRST_NAME: 'Etunimi',
  FIRST_NAME_EMPTY: 'Syötä etunimi',
  FOLLOWING_CHARGES_WILL_BE_MADE:
    'Seuraavat veloitukset luottokortiltasi tehdään  automaattisesti.',
  GO_BACK: 'Takaisin',
  GO_TO_ARTICLE: 'Siirry artikkeliin',
  GO_TO_SERVICE: 'Siirry palveluun',
  LAST_NAME: 'Sukunimi',
  LAST_NAME_EMPTY: 'Syötä sukunimi',
  LOGIN: 'Tili',
  LOGIN_VERB: 'Kirjaudu sisään',
  LOREM_IPSUM:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  I_ACCEPT_OTAVAMEDIAS: 'Hyväksyn Otavamedian',
  INPUT_EMAIL: 'Syötä sähköpostiosoite',
  INVOICE: 'Lasku',
  NEXT: 'Seuraava',
  OFFER_PERIOD: 'Tarjousjakso',
  ORDER: 'Tilaa',
  ORDER_AND_USAGE_TERMS: 'tilaus -ja käyttöehdot',
  ORDER_FAILED: 'Tilauksesi käsitellään asiakaspalvelussa.',
  ORDER_INFORMATION: 'Tiedot',
  ORDER_PERIOD_BEINGS: 'Tilausjakso alkaa',
  ORDER_PROCESSING: 'Tilaustasi käsitellään...',
  ORDER_PROCESSING_LONG:
    'Tilauksen käsittely kestää normaalia pidempään, odotathan..',
  ORDER_STARTS: 'Tilaus alkaa',
  ORDER_SUCCESS_REDIRECT:
    'Tilaus onnistui! Sinut uudelleenohjataan 5 sekunnin päästä.',
  ORDER_SUCCESSFUL: 'Tilauksesi onnistui!',
  ORDER_SUMMARY: 'Yhteenveto tilauksesta',
  OTAVAMEDIA_ACCOUNT_CREATED:
    'Antamallasi sähköpostiosoitteella on luotu Otavamedia-tili. Vahvista Otavamedia-tili ja luo tilille salasana sähköpostiisi lähetetystä linkistä 7 päivän kuluessa.',
  OTAVAMEDIA_ACCOUNT_EXISTED:
    'Antamallasi sähköpostiosoitteella on Otavamedia-tili. Mikäli olet unohtanut salasanasi, voit vaihtaa sen kirjautumisen yhteydessä.',
  OTAVAMEDIA_ACCOUNT_FAILED:
    'Otavamedia-tilin luominen ei onnistunut antamillasi tiedoilla, ota yhteyttä asiakaspalveluun',
  PAYMENT_METHOD: 'Maksutapa',
  PHONE: 'Puhelin',
  PHONE_EMPTY: 'Syötä puhelinnumero',
  PLEASE_TRY_AGAIN: 'Ole hyvä ja yritä uudelleen.',
  PLEASE_TRY_ANOTHER_METHOD: 'Ole hyvä ja kokeile toista maksutapaa.',
  POSTAL: 'Postitoimipaikka',
  POSTAL_EMPTY: 'Syötä postitoimipaikka',
  PRIVACY_NOTICE: 'tietosuojaselosteessa',
  READ_MORE: 'Lue lisää',
  REQUEST_EXPIRED: 'Pyyntö on vanhentunut, ole hyvä ja yritä uudestaan!',
  SOMETHING_WENT_WRONG: 'Pahoittelut, jotain meni vikaan.',
  SUBSCRIBING_FAILED: 'Tilauksen käsittely epäonnistui.',
  SUMMARY: 'Yhteenveto',
  THANK_YOU_FOR_ORDERING: 'Kiitos tilauksesta!',
  YOU_ALREADY_HAVE_AN_EXISTING_SUBSCRIPTION:
    'Sinulla on jo voimassa oleva tilaus.',
  YOU_ARE_BEING_REDIRECTED_TO_PAYMENT_PROVIDER:
    'Uudelleenohjataan maksupalveluun..',
  YOU_ARE_ORDERING: 'Olet tilaamassa',
  YOU_CAN_READ_CONTENT: 'Pääset lukemaan sisältöjä',
  YOU_CANNOT_PLACE_AN_ORDER_RIGHT_NOW:
    'Tilauksen tekeminen ei juuri nyt onnistu.',
  ZIP: 'Postinumero',
  ZIP_EMPTY: 'Syötä postinumero',
  ZIP_INVALID: 'Syötä kelvollinen postinumero',
};

export default t;
