import Joi from 'joi';

export interface FieldValidationErrors {
  [fieldName: string]: string;
}

type FormData = {
  [fieldName: string]: string;
};

export const validateFormData = (form: FormData, schema: Joi.AnySchema): FieldValidationErrors | undefined => {
  const { error } = schema.validate(form);
  const errorsByFormField = {} as any;
  if (error) {
    Object.values(error.details).forEach((_detail: any) => {
      const { context: { key } } = _detail;
      errorsByFormField[key] = _detail.message;
    });
    return errorsByFormField;
  }
  return undefined;
};

/**
 * Parse if user has given consent
 * If consent string (in the form of 1_2_3_4..) contains 1 and 8, user has given consent
 * @param {string} consents
 * @returns {boolean}
 */
export const consentGiven = (consents: string): boolean => {
  const parsedConsents = consents.split('_');
  if (parsedConsents?.length > 0) {
    return parsedConsents.includes?.('1') && parsedConsents.includes?.('8');
  }
  return false;
};
