import Joi from 'joi';
import t from '../../locales/fi';
import { EMAIL_REGEX } from '../utils/validators';

export const paymentFormSchema: FormValidationSchema = {
  firstName: Joi.string().required()
    .messages({
      'string.empty': t.FIRST_NAME_EMPTY,
      'any.required': t.FIRST_NAME_EMPTY,
    }),
  lastName: Joi.string().required()
    .messages({
      'string.empty': t.LAST_NAME_EMPTY,
      'any.required': t.LAST_NAME_EMPTY,
    }),
  address: Joi.string()
    .required()
    .messages({
      'any.required': t.ADDRESS_EMPTY,
      'string.empty': t.ADDRESS_EMPTY,
    }),
  postal: Joi.string()
    .required()
    .messages({
      'any.required': t.POSTAL_EMPTY,
      'string.empty': t.POSTAL_EMPTY,
    }),
  zip: Joi.string()
    .required()
    .messages({
      'any.required': t.ZIP_EMPTY,
      'string.empty': t.ZIP_EMPTY,
    }),
  phone: Joi.string().allow(''),
  email: Joi.string().regex(EMAIL_REGEX)
    .required()
    .messages({
      'any.required': t.EMAIL_EMPTY,
      'string.empty': t.EMAIL_EMPTY,
      'object.regex': t.EMAIL_INVALID,
      'string.pattern.base': t.EMAIL_INVALID,
    }),
};

export interface FormValidationSchema {
  [fieldName: string]: Joi.AnySchema
}
