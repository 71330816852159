/* eslint-disable import/prefer-default-export */

// eslint-disable-next-line max-len
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

/**
 * Check if is valid Email.
 * Validated against regex per HTML specs:
 * https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
 * @param {string} allegedEmail;
 * @returns {boolean}
 */
export const isValidEmail = (allegedEmail: string) => EMAIL_REGEX.test(allegedEmail);
