/* eslint-disable react/no-danger */
import React, {
  ComponentType,
  ReactElement,
  useContext,
  useEffect,
} from 'react';
import t from '../locales/fi';
import OrderSummary from './OrderSummary';
import Session from '../common/session';
import { Offer } from '../common/models/Offer';
import { PaymentMethod } from '../common/enums';
import { OrderContext } from '../common/context';
import { PersistedUser, User, UserType } from '../common/models/User';
import {
  appNameByUrl,
  resolveRedirectUrl,
} from '../common/utils/commonUtils';
import Item from '../common/models/gtm/Item';
import { getMagazineCategoryFromPackageId } from '../common/utils/offerUtils';
import { buildAndPushGtmEventObj, GtmEvent } from '../common/utils/gtm';

type SummaryProps = {
  paymentMethod: PaymentMethod;
  offer: Offer;
  user: User | PersistedUser;
};

const Summary: ComponentType<SummaryProps> = ({
  paymentMethod,
  offer,
  user,
}: SummaryProps): ReactElement => {
  const { finishedOrderResponse } = useContext(OrderContext) as any;

  const {
    readToken,
    subscriptionSuccessful,
    order: {
      articleURL,
      transactionId,
      price,
      packageId,
    },
    magazine: {
      name,
      salesType,
    },
    api2subscription,
  } = finishedOrderResponse;

  const {
    options: {
      paywall: {
        subscriptionSuccesfulText,
        subscriptionSuccesfulOmAccountText,
        subscriptionSuccesfulErrorListText,
      },
    },
  } = offer;

  useEffect(() => {
    const item: Item = {
      item_id: packageId,
      item_name: name,
      quantity: 1,
      currency: 'EUR',
      price: Number(typeof price === 'number' ? price : price.replace(',', '.')),
      item_brand: offer.brandsOnOffering[0],
      item_category: getMagazineCategoryFromPackageId(packageId),
      item_variant: salesType,
      affiliation: 'maksumuuri',
      item_list_id: `${offer.brandsOnOffering[0]}-paywall-placeholder`,
      item_list_name: `${offer.brandsOnOffering[0]}-maksumuuri`,
      index: 1,
    };
    const transaction = {
      transaction_id: transactionId,
      currency: 'EUR',
      value: price,
      tax: 0,
      items: [item],
    };
    buildAndPushGtmEventObj({ event: GtmEvent.PURCHASE, gtmObj: transaction });
  }, []);

  // Clear data that was stored in session storage to allow page refresh from beginning
  setTimeout(() => {
    Session.clearStore();
  }, 10000);

  const goToURL = () => {
    window.location.href = resolveRedirectUrl({
      userType: user.userType,
      readToken,
      articleURL,
      appName: appNameByUrl(articleURL),
    });
  };

  const contentBySubscriptionAndUserAccountStatus = () => {
    if (subscriptionSuccessful && user?.userType === UserType.UNCONFIRMED) {
      return subscriptionSuccesfulOmAccountText;
    }
    if (subscriptionSuccessful) return subscriptionSuccesfulText;
    return subscriptionSuccesfulErrorListText;
  };

  return (
    <>
      <div className="summary-info-text">
        <span
          dangerouslySetInnerHTML={{
            __html: contentBySubscriptionAndUserAccountStatus(),
          }}
        />
      </div>
      {subscriptionSuccessful && (
        <OrderSummary
          paymentMethod={paymentMethod}
          offer={offer}
          subscription={api2subscription}
        />
      )}
      <div className="go-to-service-button-wrapper">
        <button
          id="go-to-service"
          className="submit-button link-button active-button"
          type="button"
          onClick={goToURL}
        >
          {t.GO_TO_SERVICE}
        </button>
      </div>
    </>
  );
};

export default Summary;
