/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, Dispatch, SetStateAction } from 'react';
import {
  DisplayMode,
  InvisiblePhase, Phase, ProgressPhase,
} from './enums';
import { InitializedOrder } from './models/InitializedOrder';
import { Offer } from './models/Offer';
import { RawOrder } from './models/RawOrder';
import { PersistedUser, User } from './models/User';
import { PaywallError } from './models/types';
import FinishedOrderResponse from './models/FinishedOrder';
import { PaywallSession } from './models/Session';

// Todo: Combine some contexts to single contexts

export type PageContextType = {
  offer?: Offer | null;
  setOffer: (offer: Offer | null) => void;
  phase: InvisiblePhase | ProgressPhase;
  setPhase: (newPhase: InvisiblePhase | ProgressPhase) => void,
  error?: PaywallError;
  setError: Dispatch<SetStateAction<PaywallError>>;
  session?: PaywallSession;
  setSession: (session: PaywallSession) => void;
};

export const PageContext: React.Context<PageContextType> = createContext<PageContextType>({
  setOffer: (offer: Offer | null) => { },
  phase: Phase.LOGIN,
  setPhase: (newPhase: InvisiblePhase | ProgressPhase) => {},
  setError: ((error: PaywallError) => { }) as any,
  setSession: (session: PaywallSession) => {},
});

export type AuthContextType = {
  redirectToLogin({ signed }: Partial<Offer>): void;
  logout(redirectUrl: string | undefined): void;
  user?: User;
  persistedUser?: PersistedUser;
  setUser: (user: User) => void;
  removeUser: () => void;
};

export const AuthContext = createContext<AuthContextType>({
  redirectToLogin: ({ signed }: Partial<Offer>) => { },
  logout: (redirectUrl: string | undefined) => { },
  setUser: (user: User) => { },
  removeUser: () => { },
});

export type ProgressContextType = {
  phase: InvisiblePhase | ProgressPhase;
  setPhase: (newPhase: InvisiblePhase | ProgressPhase) => void,
};

type OrderContextType = {
  rawOrder?: RawOrder;
  setRawOrder: (rawOrder: RawOrder) => void;
  initializedOrder?: InitializedOrder;
  setInitializedOrder: (initializedOrder: InitializedOrder) => void;
  finishedOrderResponse?: FinishedOrderResponse | undefined;
  setFinishedOrderResponse: Function;
};

export const OrderContext: React.Context<OrderContextType> = createContext<OrderContextType>({
  setRawOrder: (rawOrder: RawOrder) => {},
  setInitializedOrder: (intializedOrder: InitializedOrder) => {},
  setFinishedOrderResponse: (data: object) => {},
});

type OrderHandlingReducerContextType = {
  state: any;
  dispatch: Function;
};

// eslint-disable-next-line max-len
export const OrderHandlingReducerContext: React.Context<OrderHandlingReducerContextType> = createContext<OrderHandlingReducerContextType>({
  state: {},
  dispatch: (state: {}) => { },
});

type ToasterContextType = {
  showToaster: Function;
  hideToaster: Function;
  content?: string;
  visible: boolean;
  toasterVisible: boolean;
};

export const ToasterContext: React.Context<ToasterContextType> = createContext<ToasterContextType>({
  showToaster: (content: string): void => {},
  hideToaster: (): void => { },
  visible: false,
  toasterVisible: false,
});

type DisplayContextType = {
  displayMode: DisplayMode
  setDisplayMode: Function;
};

export const DisplayContext: React.Context<DisplayContextType> = createContext<DisplayContextType>({
  displayMode: DisplayMode.DESKTOP,
  setDisplayMode: (displayMode: DisplayMode): void => {},
});
