/* eslint-disable react/no-danger */
import React, {
  ReactElement,
  ComponentType,
  SetStateAction,
  Dispatch,
} from 'react';
import t from '../../locales/fi';
import Checkbox from '../Checkbox';
import { PaymentMethod } from '../../common/enums';
import { Offer, OrderType } from '../../common/models/Offer';
import Link from '../Link';
import OfferPaymentMethod from './OfferPaymentMethod';

type PaymentProps = {
  offer: Offer;
  paymentMethod: PaymentMethod | undefined;
  setPaymentMethod: Dispatch<SetStateAction<PaymentMethod>>;
  termsAccepted: boolean;
  setTermsAccepted: Dispatch<SetStateAction<boolean>>;
};

const TermsApprovalLabel = () => (
  <span className="terms-approval-label">
    {`${t.I_ACCEPT_OTAVAMEDIAS} `}
    <Link
      customClass="terms-link"
      href="https://otavamedia.fi/tilaus-ja-kayttoehdot/"
    >
      {t.ORDER_AND_USAGE_TERMS}
    </Link>
  </span>
);

const PaymentSelection: ComponentType<PaymentProps> = ({
  offer,
  paymentMethod,
  setPaymentMethod,
  termsAccepted,
  setTermsAccepted,
}: PaymentProps): ReactElement => {
  const {
    jatkuvaSubscriptionInfoText,
    creditcardInfoTextContinuousSub,
    maSubscriptionInfoText,
    creditcardInfoText,
    invoicingInfoText,
  } = offer.options.paywall;

  const {
    renewalPriceString,
  } = offer.magazines![0].siteOptions;
  const { payment: { installmentsMessage } } = offer;

  const includePaymentInstallments = () => {
    if (paymentMethod === PaymentMethod.INVOICE && installmentsMessage) {
      return (
        <div
          className="payment-info-installments-text"
          id="payment-info-installments-text"
        >
          <span dangerouslySetInnerHTML={{ __html: installmentsMessage }} />
        </div>
      );
    }
    return null;
  };

  const getInvoiceOrCardInfoText = () => {
    if (paymentMethod === PaymentMethod.INVOICE) {
      return (
        <div className="payment-info-text" id="payment-info-text-invoicing">
          <span dangerouslySetInnerHTML={{ __html: invoicingInfoText }} />
        </div>
      );
    }
    if (paymentMethod === PaymentMethod.CARD) {
      let infoString = `${creditcardInfoText}`;
      if (offer.magazines![0].orderType === OrderType.CONTINUOUS) {
        infoString += ` ${creditcardInfoTextContinuousSub}`;
      }
      return (
        <div className="payment-info-text" id="payment-info-text-credit-card">
          <span dangerouslySetInnerHTML={{ __html: infoString }} />
        </div>
      );
    }
    return null;
  };

  const getSubscriptionInfoText = () => {
    switch (offer.magazines![0].orderType) {
      case OrderType.FIXED:
        return (
          <div
            className="payment-info-text"
            id="subscription-info-fixed-subscription"
          >
            <span
              dangerouslySetInnerHTML={{ __html: maSubscriptionInfoText }}
            />
          </div>
        );
      case OrderType.CONTINUOUS:
        return (
          <div
            className="payment-info-text"
            id="subscription-info-continuous-subscription"
          >
            <span
              dangerouslySetInnerHTML={{ __html: jatkuvaSubscriptionInfoText }}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <OfferPaymentMethod
        setPaymentMethod={setPaymentMethod}
        paymentMethod={paymentMethod!}
        offer={offer}
      />
      {includePaymentInstallments()}
      {getInvoiceOrCardInfoText()}
      {renewalPriceString && paymentMethod === PaymentMethod.CARD ? (
        <span className="payment-info-text renewal-price-text" id="renewal-price">
          {renewalPriceString}
        </span>
      ) : (
        ''
      )}
      {getSubscriptionInfoText()}
      <Checkbox
        id="terms-approval"
        label={TermsApprovalLabel()}
        onChange={() => setTermsAccepted(!termsAccepted)}
      />
    </>
  );
};

export default PaymentSelection;
