import {
  ComponentType, useContext, useEffect,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { AuthContext, PageContext } from '../../common/context';

/* There are two ways to navigate to logout page: With redirectTo query param or without.
*  Query param way is used in case session has been emptied. Otherwise articleURL can be fetched from
*  rawOrder object.
*/
const Logout: ComponentType<any> = (): null => {
  const { logout } = useContext(AuthContext);
  const { session } = useContext(PageContext);

  const [params] = useSearchParams();

  useEffect(() => {
    logout(params.get('redirectTo') || session?.params?.articleURL);
  }, []);

  return (
    null
  );
};

export default Logout;
