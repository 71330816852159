import { ComponentType, ReactElement } from 'react';

const Spinner: ComponentType = (): ReactElement => (
  <div className="lds-spinner">
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default Spinner;
