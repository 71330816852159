/* eslint-disable react/no-danger */
import React, {
  ReactElement, ComponentType,
} from 'react';
import { Offer, OrderType } from '../../common/models/Offer';

interface TermsAndConditionsProps {
  offer: Offer;
}

const TermsAndConditions: ComponentType<TermsAndConditionsProps> = ({
  offer,
}: TermsAndConditionsProps): ReactElement => {
  const {
    emailInfoText,
    jatkuvaSubscriptionTerms,
    maSubscriptionTerms,
  } = offer!.options!.paywall;

  const getSubscriptionTerms = () => {
    switch (offer.magazines![0].orderType) {
      case OrderType.FIXED:
        return (
          <span dangerouslySetInnerHTML={{ __html: maSubscriptionTerms }} />
        );
      case OrderType.CONTINUOUS:
        return (
          <span dangerouslySetInnerHTML={{ __html: jatkuvaSubscriptionTerms }} />
        );
      default:
        return (null);
    }
  };

  return (
    <div className="terms-and-conditions">
      <div className="payment-info-text">
        <span dangerouslySetInnerHTML={{ __html: emailInfoText }} />
      </div>
      <div className="payment-info-text">
        {getSubscriptionTerms()}
      </div>
    </div>
  );
};

export default TermsAndConditions;
