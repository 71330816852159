/* eslint-disable no-case-declarations */
import {
  ComponentType,
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  ORDER_CHARGE_CARD_FAILURE,
  ORDER_INIT_SUCCESS,
  ORDER_COMPLETE,
  ORDER_SUBSCRIBE,
  ORDER_SUBSCRIBE_FAILURE,
  INIT_HANDLE_ORDER,
  INIT_CARD_PAYMENT_ORDER,
  INIT_INVOICE_PAYMENT_ORDER,
  ORDER_ADD_CARD,
  ORDER_INVOICE_POST_INIT,
  ORDER_INVOICE_POST_INIT_FAILURE,
  ORDER_INIT_SUBSCRIBE,
  ORDER_ADD_CARD_FAILURE,
} from '../common/actions/types/order';
import { useApi } from '../common/api';
import {
  CREDITCARD_ORDER_INIT_ENDPOINT,
  ErrorCodes,
  INVOICE_ORDER_INIT_ENDPOINT,
  ORDER_CANCEL_ENDPOINT,
  ORDER_SUBSCRIBE_ENDPOINT,
} from '../common/constants';
import {
  PageContext,
  OrderContext,
  OrderHandlingReducerContext,
  ToasterContext,
  AuthContext,
} from '../common/context';
import { PaymentMethod, Phase } from '../common/enums';
import {
  clearUrl, getUserFromCancelResponse, parseUrlParams,
} from '../common/utils/commonUtils';
import AddCardForm from '../components/AddCardForm';
import Spinner from '../components/Spinner';
import t from '../locales/fi';
import { PaymentMethodOrder } from '../common/models/types';

type HandleOrderProps = {
  setPaymentMethod: Dispatch<SetStateAction<PaymentMethod>>;
};

/**
 * Handle order component
 */
const HandleOrder: ComponentType<HandleOrderProps> = ({
  setPaymentMethod,
}: HandleOrderProps): ReactElement => {
  const paymentApi = useApi(
    {
      errorCode: '',
      error: false,
      orderId: '',
      urlParams: {},
      redirectUrl: '',
    },
    {},
    { headers: { 'x-api-key': 'REPLACEME' as string } },
  );
  const { setPhase } = useContext(PageContext);
  const {
    rawOrder,
    initializedOrder,
    setInitializedOrder,
    setFinishedOrderResponse,
  } = useContext(OrderContext);
  const { setUser } = useContext(AuthContext);
  const { state, dispatch } = useContext(OrderHandlingReducerContext);
  const { showToaster } = useContext(ToasterContext);
  const [cardPaymentParams, setCardPaymentParams] = useState('');
  const [triggerRedirect, setTriggerRedirect] = useState(false);
  const [waitingText, setWaitingText] = useState(t.ORDER_PROCESSING);

  useEffect(() => {
    switch (state.type) {
      case INIT_HANDLE_ORDER:
        dispatch({ type: INIT_HANDLE_ORDER });
        break;
      case INIT_INVOICE_PAYMENT_ORDER:
        paymentApi.post(INVOICE_ORDER_INIT_ENDPOINT, {
          body: JSON.stringify({ ...rawOrder }),
        });
        dispatch({ type: ORDER_INVOICE_POST_INIT });
        break;
      case INIT_CARD_PAYMENT_ORDER:
        dispatch({ type: ORDER_ADD_CARD });
        paymentApi.post(CREDITCARD_ORDER_INIT_ENDPOINT, {
          body: JSON.stringify({ ...rawOrder }),
        });
        break;
      default:
        break;
    }
  }, [state.type]);

  // Handling after redirect from Pay trail
  useEffect(() => {
    const urlParams = new URL(window.location.href).searchParams;
    const parsedParams = parseUrlParams(urlParams);
    const { orderId, success: successString } = parsedParams;
    if (successString !== undefined) {
      setPaymentMethod(PaymentMethod.CARD); // Undefined before this due to redirect
      const success: boolean = successString === 'true';
      if (success) {
        setInitializedOrder({
          orderId,
          paymentMethod: PaymentMethodOrder.CARD,
          ...parsedParams,
        });
        dispatch({ type: ORDER_INIT_SUCCESS });
      } else {
        console.log('Card add cancelled.');
        paymentApi.get(ORDER_CANCEL_ENDPOINT, {
          queryParams: { orderId } as any,
        });
        dispatch({ type: ORDER_ADD_CARD_FAILURE });
      }
      // When user presses previous from browser
    } else if (!state?.type) {
      setPhase(Phase.CONTACT_AND_PAYMENT);
    }
    clearUrl();
  }, [window.location.pathname]);

  /*
   * Fire all other actions based on order state change
   */
  useEffect(() => {
    if (!paymentApi.loading) {
      const { data, error: apiError } = paymentApi;
      switch (state.type) {
        case ORDER_INVOICE_POST_INIT:
          if (data.errorCode || apiError) {
            dispatch({ type: ORDER_INVOICE_POST_INIT_FAILURE });
            break;
          }
          dispatch({ type: ORDER_INIT_SUCCESS });
          break;
        case ORDER_INVOICE_POST_INIT_FAILURE:
          showToaster(t.SOMETHING_WENT_WRONG);
          setPhase(Phase.CONTACT_AND_PAYMENT);
          break;
        case ORDER_ADD_CARD:
          if (data.errorCode || apiError) {
            dispatch({ type: ORDER_CHARGE_CARD_FAILURE });
            break;
          }
          localStorage.setItem('orderId', JSON.stringify(paymentApi.data.orderId));
          const returnedUrlParams: any = paymentApi.data;
          setCardPaymentParams(returnedUrlParams);
          setTimeout(() => {
            setTriggerRedirect(true);
          }, 500);
          break;
        case ORDER_ADD_CARD_FAILURE:
          console.log('Adding card failed', data);
          setUser(getUserFromCancelResponse(data));
          localStorage.removeItem('orderId');
          showToaster(`${t.ADDING_CARD_FAILED} ${t.PLEASE_TRY_AGAIN}`);
          setPhase(Phase.CONTACT_AND_PAYMENT);
          break;
        case ORDER_CHARGE_CARD_FAILURE:
          console.log('Charging card failed', data);
          showToaster(
            `${t.CHARGING_CARD_FAILED} ${t.PLEASE_TRY_ANOTHER_METHOD}`,
          );
          setPhase(Phase.CONTACT_AND_PAYMENT);
          break;
        case ORDER_INIT_SUCCESS:
          dispatch({ type: ORDER_INIT_SUBSCRIBE });
          break;
        case ORDER_INIT_SUBSCRIBE:
          paymentApi.post(ORDER_SUBSCRIBE_ENDPOINT, {
            body: JSON.stringify({
              initializedOrder,
            }),
          });
          dispatch({ type: ORDER_SUBSCRIBE });
          setTimeout(() => {
            setWaitingText(t.ORDER_PROCESSING_LONG);
          }, 10000);
          break;
        case ORDER_SUBSCRIBE:
          if (data.errorCode || apiError) {
            if (data.errorCode === ErrorCodes.CARD_CHARGE_FAILED) {
              dispatch({ type: ORDER_CHARGE_CARD_FAILURE });
            }
            dispatch({ type: ORDER_SUBSCRIBE_FAILURE });
            break;
          }
          dispatch({ type: ORDER_COMPLETE });
          break;
        case ORDER_SUBSCRIBE_FAILURE:
          console.log('Subscribing failed', data);
          showToaster(t.SUBSCRIBING_FAILED);
          setPhase(Phase.CONTACT_AND_PAYMENT);
          break;
        case ORDER_COMPLETE:
          setFinishedOrderResponse(data);
          setTimeout(() => {
            setPhase(Phase.SUMMARY);
          }, 5000);
          break;
        default: {
          break;
        }
      }
    }
  }, [state.type, paymentApi.loading]);

  const getContent = () => {
    switch (state.type) {
      case ORDER_CHARGE_CARD_FAILURE:
        return <div>{t.CARD_CHARGE_FAILED}</div>;
      case ORDER_SUBSCRIBE:
        return (
          <div>
            <Spinner />
            {waitingText}
          </div>
        );
      case ORDER_ADD_CARD:
        return (
          <div>
            <Spinner />
            {t.YOU_ARE_BEING_REDIRECTED_TO_PAYMENT_PROVIDER}
          </div>
        );
      case ORDER_COMPLETE:
        return (
          <div>
            <Spinner />
            {t.ORDER_SUCCESS_REDIRECT}
          </div>
        );
      default:
        return (
          <div>
            <Spinner />
          </div>
        );
    }
  };
  return (
    <>
      {triggerRedirect && <AddCardForm params={cardPaymentParams as any} />}
      {getContent()}
    </>
  );
};

export default HandleOrder;
