import { ComponentType, ReactElement, useEffect } from 'react';
import { CHECKOUT_ADD_CARD_URL } from '../common/constants';

type AddCardFormParams = {
  orderId: string;
  paymentMethod: string;
  'checkout-account': string;
  'checkout-algorithm': string;
  'checkout-method': string;
  'checkout-callback-cancel-url': string;
  'checkout-callback-success-url': string;
  'checkout-nonce': string;
  'checkout-redirect-cancel-url': string;
  'checkout-redirect-success-url': string;
  'checkout-timestamp': string;
  language: string;
  signature: string;
};

type AddCardFormProps = {
  params: AddCardFormParams;
};

/**
 * Hidden form which is automatically submitted when component is rendered.
 * @param {AddCardFormProps} params Object containing form key-value pairs
 */
const AddCardForm: ComponentType<AddCardFormProps> = ({
  params,
}: AddCardFormProps): ReactElement => {
  useEffect(() => {
    document.forms.namedItem('addCardForm')!.submit();
  }, []);

  return (
    <form method="post" action={CHECKOUT_ADD_CARD_URL} id="addCardForm">
      {Object.keys(params).map((key) => (
        <input
          type="hidden"
          name={key}
          key={key}
          value={(params as any)[key]}
        />
      ))}
    </form>
  );
};

export default AddCardForm;
