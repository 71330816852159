import { ComponentType, useMemo, useState } from 'react';
import { api, useApi } from '../api';
import {
  AUTH_CALLBACK,
  IS_DEV,
  LOGOUT_ENDPOINT,
  MOCK_AUTH_PARAMS,
  OMAUTH_AUTH_LOGIN_ENDPOINT,
  OMAUTH_BASE_URL,
  OMAUTH_CLIENT_ID,
  OTAVAMEDIA_URL,
} from '../constants';
import { AuthContext } from '../context';
import Session from '../session';
import { GenericProviderProps, OmAuthLoginParams } from '../models/types';
import { Offer } from '../models/Offer';
import { User } from '../models/User';
import { useSessionHook } from '../hooks';

const baseOmAuthLoginParams: OmAuthLoginParams = {
  response_type: 'code',
  scope: 'openid profile',
  client_id: OMAUTH_CLIENT_ID!,
  redirect_uri: AUTH_CALLBACK,
  state: '',
};

const getOmAuthLoginParams = (signedOffer: string): OmAuthLoginParams => ({
  ...baseOmAuthLoginParams,
  state: signedOffer,
});

const getFilteredUser = (user: any): object => {
  const fieldsAllowedToPersist = ['masterId', 'userType'];
  const filteredUser = {} as any;
  fieldsAllowedToPersist.forEach((field: any) => {
    if (fieldsAllowedToPersist.includes(field)) {
      filteredUser[field] = user[field];
    }
  });
  return filteredUser;
};

const AuthProvider: ComponentType<GenericProviderProps> = ({
  children,
}: GenericProviderProps) => {
  const { loading } = useApi(
    {},
    {},
    { headers: { 'x-api-key': 'REPLACEME' as string } },
  );
  /* User is not preserved on redirects. Some info is still required, so we persist
     some user data. Therefore, persisted user exists.
  */
  const [user, setStateUser] = useState<User>();
  const [persistedUser, setPersistedUser] = useSessionHook('persistedUser');
  const setUser = (userToSave?: User) => {
    setStateUser(userToSave);
    setPersistedUser(getFilteredUser(userToSave));
  };
  const removeUser = () => {
    setUser(undefined);
  };
  const logout = async (redirectUrl:string | undefined = OTAVAMEDIA_URL) => {
    await api(LOGOUT_ENDPOINT, { method: 'POST' });
    Session.clearStore();
    window.location.assign(redirectUrl);
  };
  const redirectToCallback = (testParams: object) => {
    window.location.href = `/auth_callback?code=mock?${new URLSearchParams(
      testParams as URLSearchParams,
    ).toString()}&iss=https%3A%2F%2Fomauth-frontend.omauthdev.net`;
  };

  const redirectToLogin = ({ signed }: Partial<Offer> = {}) => {
    if (IS_DEV && sessionStorage.getItem(MOCK_AUTH_PARAMS) !== null) {
      redirectToCallback(JSON.parse(sessionStorage.getItem(MOCK_AUTH_PARAMS)!));
      return;
    }
    // eslint-disable-next-line no-restricted-globals
    history.pushState('', '', '/logout');
    window.location.href = `${OMAUTH_BASE_URL}/${OMAUTH_AUTH_LOGIN_ENDPOINT}?${new URLSearchParams(
      getOmAuthLoginParams(signed!),
    ).toString()}`;
  };

  const value = useMemo(
    () => ({
      logout,
      loading,
      user,
      persistedUser,
      setUser,
      removeUser,
      redirectToLogin,
    }),
    [loading, user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
