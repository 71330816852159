import { v4 as uuidv4 } from 'uuid';

interface PaywallSessionParams {
  [k: string]: any;
}

export type PaywallSession = {
  id: string | undefined;
  paywallSessionId: string;
  sourceUrl: string;
  referrerUrl?: string;
  expires: number;
  params?: PaywallSessionParams | null;
  consentGiven: boolean;
};

export const initSession = ({
  referrerSessionId,
  referrerUrl,
  sourceUrl = `${window.location.protocol}//${window.location.host + window.location.pathname}`,
  consentGiven = false,
}: {
  referrerSessionId?: string;
  referrerUrl?: string;
  sourceUrl?: string;
  consentGiven?: boolean;
}): PaywallSession => ({
  id: referrerSessionId,
  paywallSessionId: uuidv4(),
  referrerUrl,
  expires: Date.now() + 3600,
  sourceUrl,
  consentGiven,
  params: null,
});
