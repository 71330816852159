/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react';
import {
  DisplayMode,
  InvisiblePhase,
  Phase,
  ProgressPhase,
} from './enums';
import { Offer } from './models/Offer';
import { PaywallSession } from './models/Session';
import Session from './session';
import { Storage } from './models/types';

export const useSessionHook = (sessionKey: string, defaultValue?: any, storageType = Storage.SESSION):
[any, (item: any) => void] => {
  const sessionItem: null | any = Session.getStore({ key: sessionKey, defaultValue, storageType });
  const [_item, _setItem] = useState(sessionItem || defaultValue);
  const setItem = (item: any) => {
    Session.setStore({ key: sessionKey, value: JSON.stringify(item), storageType });
    _setItem(item);
  };

  return [_item, setItem];
};

export const usePhaseHook = (): [
  ProgressPhase | InvisiblePhase,
  (phase: ProgressPhase | InvisiblePhase) => void,
] => useSessionHook('phase', Phase.LOGIN);

export const usePaywallSessionHook = (): [
  PaywallSession,
  (session: PaywallSession) => void,
] => {
  const [_session, _setSession] = useSessionHook('session', {}, Storage.LOCAL);
  const setSession = (session: PaywallSession) => {
    const cookieExpiry = session.expires - Date.now();
    document.cookie = `session=${session?.id};max-age=${cookieExpiry}`;
    if (session.params) document.cookie = `params=${JSON.stringify(session.params)};max-age=${cookieExpiry}`;
    _setSession(session);
  };
  return [_session, setSession];
};

export const useOfferHook = (): [
  Offer | undefined,
  (offer: Offer | null) => void,
] => useSessionHook('offer');

export const useToasterHook = (): Array<any> => {
  const [content, setContent] = useState('');
  const [visible, setVisible] = useState(false);

  const showToaster = (newContent: string) => {
    setContent(newContent);
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
    }, 10000);
  };

  const hideToaster = () => {
    setVisible(false);
  };

  return [content, visible, showToaster, hideToaster];
};

export const useDisplayModeHook = (): [
  DisplayMode, (displayMode: DisplayMode) => void,
] => useSessionHook('display');
