import { User, UserType } from '../models/User';
import Session from '../session';
import {
  IS_DEV,
  AUTH_USER_ENDPOINT,
  MOCK_AUTH_PARAMS,
  RAKENNUSMAAILMA_PROD_URL,
  RAKENNUSMAAILMA_APPNAME,
  DEFAULT_APPNAME,
  RAKENNUSMAAILMA_DEV_URL,
  TRACKING_ENDPOINT,
} from '../constants';
import { api } from '../api';

/**
 * Helper type for merging two types into one. See order.ts for an example.
 */
export type Merge<A, B> = {
  [K in keyof A]: K extends keyof B ? B[K] : A[K];
} & B extends infer O
  ? { [K in keyof O]: O[K] }
  : never;

export const parseUrlParams = (urlSearchParams: URLSearchParams): any => {
  const parsedParams = {};
  urlSearchParams.forEach((value: any, key: any) => {
    (parsedParams as any)[key] = value;
  });
  return parsedParams;
};

/**
 * Get updated user if properties have changed.
 * @param {object} properties object with properties
 * @param {User} user existing user
 * @returns {User|undefined} New user if fields have changed, otherwise undefined
 */
export const getUpdatedUserIfHasChanged = (
  properties: any,
  user: any,
): User | undefined => {
  let changed = false;
  const newUser = { ...user } as any;
  Object.keys(properties).forEach((key: any) => {
    const newValue = properties[key];
    newUser[key] = newValue;
    changed = true;
  });
  return changed ? (newUser as User) : undefined;
};

export const clearUrl = () => window.history.replaceState(null, '', '/');

export const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);

export const getUserFromCancelResponse = (order: any) => {
  const {
    payerFirstName,
    payerLastName,
    payerEmail,
    payerStreetAddress,
    payerZipCode,
    payerPhoneNumber,
    payerCity,
    payerMasterasiakasId,
  } = order;
  const user = {
    masterId: payerMasterasiakasId || '',
    firstName: payerFirstName || '',
    lastName: payerLastName || '',
    contactInfo: {
      email: payerEmail || '',
      phoneNumber: payerPhoneNumber || '',
    },
    address: {
      streetName: payerStreetAddress || '',
      zipCode: payerZipCode || '',
      city: payerCity || '',
    },
  } as User;
  return user;
};
export const formFromUser = (user?: User) => {
  const formData = {
    phone: user?.contactInfo.phoneNumber || '',
    address: user?.address?.streetName || '',
    zip: user?.address?.zipCode || '',
    postal: user?.address?.city || '',
    email: user?.contactInfo.email || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
  };
  return formData;
};

export const resolveAuthEndpoint = () => {
  const useMock = IS_DEV && sessionStorage.getItem(MOCK_AUTH_PARAMS) !== null;
  return useMock ? `${AUTH_USER_ENDPOINT}/test` : AUTH_USER_ENDPOINT;
};

export const clearSession = (): void => {
  Session.clearStore();
  window.location.href = '/';
};

export const setFavIcon = (iconSrc: string): void => {
  let link = document.querySelector("link[rel~='icon']") as any;
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = iconSrc;
};

export const setPageTitle = (title: string): void => {
  document.title = title;
};

/**
 * Get finnish date string from Date object
 * @param {Date} date a date object
 * @returns {string} In the format of DD.MM.YYYY
 */
export const getFinnishDateStringFromDate = (date: Date) => date.toLocaleString('fi').split(' ')[0];

/**
 * Add query parameters to url
 *
 * google.com/?param1=value1 -> google.com/?param1=value1&addedParam1=addedValue1
 * @param  {object} param0
 * @param  {string} param0.url
 * @param  {object} param0.params
 * @returns {string} Modified url as string
 */
export const addQueryParametersToUrl = ({
  url,
  params,
}: {
  url: string;
  params: object;
}): string => {
  const paramsToAdd = { ...params } as any;
  const newUrl = new URL(url);
  const searchParams = new URLSearchParams(newUrl.search);
  Object.keys(paramsToAdd).forEach((key: any) => {
    const value = paramsToAdd[key];
    searchParams.set(key, value);
  });
  newUrl.search = searchParams.toString();
  return newUrl.toString();
};

/**
 * https://google.com/this/thing -> google.com
 * @param {string} url
 * @returns {string}
 */
export const domainFromUrl = (url: string) => url
  .replace('http://', '')
  .replace('https://', '')
  .replace('www.', '')
  .split(/[/?#]/)[0];

export const appNameByUrl = (url: string) => {
  const domain = domainFromUrl(url);
  if ([RAKENNUSMAAILMA_PROD_URL, RAKENNUSMAAILMA_DEV_URL].includes(domain)) {
    return RAKENNUSMAAILMA_APPNAME;
  }
  return DEFAULT_APPNAME;
};

/**
 * Get Oauth params for Aste SSO service.
 * Some domains require a different app id based on magazine domain
 * @param articleUrl article url
 * @returns {object}
 */
export const asteOauthParamsByArticleUrl = (articleUrl: string) => {
  const defaultParams = {
    app_name: appNameByUrl(articleUrl),
    option: 'oauthredirect', // Triggers login at magazine end
  };
  return defaultParams;
};

/**
 * Resolve redirect url
 * If user is unconfirmed, redirect url must be redirected via different address.
 * @param param0
 * @returns {string}
 */
export const resolveRedirectUrl = ({
  userType,
  articleURL,
  readToken,
  appName,
}: {
  userType: UserType;
  articleURL: string;
  readToken?: string;
  appName: string;
}): string => {
  const domain = domainFromUrl(articleURL);
  const newUrl = `https://${domain}/asauth/remote-auth`;
  const params = { articleURL, app_name: appName } as any;
  if (readToken) {
    params.astoken = readToken;
  }
  if (userType === UserType.UNCONFIRMED) {
    return addQueryParametersToUrl({
      url: newUrl,
      params: { ...params },
    });
  }
  return addQueryParametersToUrl({
    url: newUrl,
    params: { ...params, dologin: 1 },
  });
};

export const filterEmptyParams = (obj: object) => Object.fromEntries(
  Object.entries(obj).filter(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_, v]) => v != null && v !== '' && typeof v !== 'undefined',
  ),
);

export const sendTrackingData = async ({
  receiveType,
  sourceSid,
  referrerUrl,
  referrerSid,
  masterId,
  articleURL,
  sourceUrl,
}: {
  receiveType?: string;
  sourceSid?: string;
  referrerUrl?: string;
  referrerSid?: string;
  masterId?: string;
  articleURL?: string;
  sourceUrl?: string;
}) => {
  try {
    await api(TRACKING_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({
        trackingData: {
          receiveType,
          sourceSid,
          referrerUrl,
          referrerSid,
          sourceUrl,
          masterId,
          articleURL,
        },

      }),
    });
  } catch (error) {
    console.error('Error sending data to Sandbox', error);
  }
};
