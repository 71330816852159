import {
  ComponentType, useContext, useEffect,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { api, useApi } from '../../common/api';
import { AUTH_TOKEN_ENDPOINT, AUTH_CALLBACK, DECRYPT_TOKEN_ENDPOINT } from '../../common/constants';
import {
  PageContext, AuthContext,
} from '../../common/context';
import { Phase } from '../../common/enums';
import { User } from '../../common/models/User';
import { resolveAuthEndpoint } from '../../common/utils/commonUtils';

const Callback: ComponentType = (): null => {
  const {
    offer, setError, setPhase,
  } = useContext(PageContext);
  const { setUser, redirectToLogin } = useContext(AuthContext);
  const {
    post, loading,
  } = useApi({}, {}, { headers: { 'x-api-key': 'REPLACEME' as string } });

  const [params] = useSearchParams();

  const existingUserLogin = async (code: string) => {
    await post(`${AUTH_TOKEN_ENDPOINT}`, { body: JSON.stringify({ code, redirect_uri: AUTH_CALLBACK }) as any });
    const { data: user }: { data: User } = await api(resolveAuthEndpoint(), { method: 'GET' });
    setUser(user);
  };

  // New user redirect from Aste login - user info is encrypted in token
  const newUserLogin = async (token: string) => {
    const response = await api(DECRYPT_TOKEN_ENDPOINT, { body: JSON.stringify({ token }), method: 'POST' });
    const { data, status }: { data: User | undefined, status: number } = response;
    if (status !== 201) {
      setError({ errorMessage: undefined });
      throw new Error('Error decrypting new user', data);
    }
    setUser(data!);
  };

  useEffect((): void => {
    const tokenParam: string | null = params.get('token');
    const codeParam: string | null = params.get('code');
    if (!loading && (tokenParam || codeParam)) {
      try {
        if (tokenParam) {
          newUserLogin(tokenParam);
        } else {
          existingUserLogin(codeParam!);
        }
        setPhase(Phase.PRE_CHECK);
      } catch (error: any) {
        if (error.statusCode === 403) {
          redirectToLogin(offer!);
        }
        setError({ errorMessage: undefined });
      }
    }
  }, [window.location.pathname]);

  return null;
};

export default Callback;
