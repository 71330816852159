// Todo type this properly after it is clear that these will be used

export enum UserType {
  UNCONFIRMED = 'UNCONFIRMED',
  CONFIRMED = 'CONFIRMED',
}

/** todo */
export type User = {
  masterId?: string;
  kayakId?: string;
  firstName: string;
  lastName: string;
  contactInfo: ContactInfo;
  address: Address;
  subscriptions: Array<object> | null;
  userType: UserType;
};

/**
 * This is a stripped version of user that is safe(r) to save to storage
 */
export type PersistedUser = {
  masterId?: string;
  userType: UserType;
};

export const emptyUser: User = {
  masterId: '',
  kayakId: '',
  firstName: '',
  lastName: '',
  contactInfo: {} as ContactInfo,
  address: {} as Address,
  subscriptions: null,
  userType: UserType.UNCONFIRMED,
};

/** todo */
export type Address = {
  streetName: string;
  zipCode: string;
  city: string;
  country: string;
  countryIso2: string;
};

/** todo */
export type ContactInfo = {
  email: string;
  phoneNumber: string;
  birthday: string;
};
