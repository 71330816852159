import { ReactElement, ReactNode } from 'react';
import { Offer } from './Offer';

export type QueryParamsProp = {
  [key: string]: number | string | boolean | number[] | string[] | boolean[]
} | null | undefined;

export type QueryParams = QueryParamsProp | null | undefined;

export type RequestMethod = 'POST' | 'GET' | 'DELETE' | 'PUT';
export type ApiPayload = {
  method?: RequestMethod,
  body?: any, queryParams?: QueryParams;
  customHeaders?: RequestInit['headers'],
  customEndpoint?: string
};

export type EAPIResponse = Offer;

export type PaywallError = { errorMessage: string | undefined | ReactElement } | undefined;

export type OmAuthLoginParams = {
  response_type: string;
  scope: string;
  client_id: string;
  redirect_uri: string;
  state: string;
};

export type AuthProviderProps = {
  children: ReactNode;
};

export type OrderContactForm = {
  phone: string;
  address: string;
  zip: string;
  postal: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type GenericProviderProps = {
  children: ReactNode;
};

export enum Storage {
  LOCAL = 'LOCAL',
  SESSION = 'SESSION',
  SITE = 'SITE',
}

export enum PaymentMethodOrder {
  CARD = 'card',
  MOBILEPAY = 'mobilepay',
  INVOICING_PAYTRAIL = 'paytrail', // verkkomaksu (bank invoice)
  INVOICING_E_IDENT = 'eident',
  INVOICING_CALL = 'call',
}
