import {
  ComponentType, useContext, useEffect,
} from 'react';
import { AuthContext, PageContext } from '../../common/context';

/* eslint-disable react/jsx-no-useless-fragment */
const Login: ComponentType<any> = (): null => {
  const { offer } = useContext(PageContext);
  const { user, removeUser, redirectToLogin } = useContext(AuthContext);

  useEffect(() => {
    if (user) removeUser();
    redirectToLogin(offer!);
  }, []);

  return null;
};

export default Login;
