/* eslint-disable react/jsx-props-no-spreading */
import {
  useContext,
  ComponentType,
  ReactElement,
  useState,
  useEffect,
} from 'react';

import { Navigate } from 'react-router-dom';
import Summary from './Summary';
import HandleOrder from './HandleOrder';
import { AuthContext, OrderContext, PageContext } from '../common/context';
import { PaymentMethod, Phase } from '../common/enums';
import { IS_DEV } from '../common/constants';
import Session from '../common/session';
import {
  getDefaultPaymentMethod,
  hasExistingSubscription,
  queryParamsFromOffer,
} from '../common/utils/offerUtils';
import ContactAndPayment from './ContactAndPayment';
import TermsAndConditions from '../components/ContactAndPayment/TermsAndConditions';
import Spinner from '../components/Spinner';
import t from '../locales/fi';
import { User, UserType } from '../common/models/User';
import { Offer } from '../common/models/Offer';
import { addQueryParametersToUrl, asteOauthParamsByArticleUrl, sendTrackingData } from '../common/utils/commonUtils';

const clearSession = (): void => {
  Session.clearStore();
  window.location.href = '/';
};

type PageProps = {
  offer: Offer;
  user: User;
};

const Page: ComponentType<any> = ({ offer, user }: PageProps): ReactElement => {
  const {
    phase, setPhase, setError, session,
  } = useContext(PageContext);
  const { rawOrder, setRawOrder } = useContext(OrderContext);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    getDefaultPaymentMethod(offer) as PaymentMethod,
  );
  const { persistedUser } = useContext(AuthContext);
  const {
    params,
    id: referrerSessionId,
    paywallSessionId,
    sourceUrl,
    referrerUrl,
    consentGiven,
  } = session!;

  const {
    articleURL,
  } = params || {};

  const contentByPhase = (): ReactElement<any, any> => {
    switch (phase) {
      case Phase.LOGIN:
        // This case should not exist. Only if user somehow reloads tilaa-page with empty localStorage
        return (
          <Navigate
            to={offer ? `/${queryParamsFromOffer(offer).toString()}` : '/'}
          />
        );
      case Phase.CONTACT_AND_PAYMENT:
        return (
          <ContactAndPayment
            offer={offer}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          />
        );
      case Phase.HANDLE_ORDER:
        return <HandleOrder setPaymentMethod={setPaymentMethod} />;
      case Phase.SUMMARY:
        return (
          <Summary paymentMethod={paymentMethod} offer={offer} user={user || persistedUser} />
        );
      default:
        return <Spinner />;
    }
  };

  useEffect(() => {
    // when returning from paytrail via browsers back arrow, redirect to handling page
    const orderId = JSON.parse(localStorage.getItem('orderId')!);
    if (orderId && phase === Phase.PRE_CHECK) {
      window.location.assign(`/order/card?orderId=${orderId}&success=false`);
    }
    localStorage.removeItem('orderId');
    setPhase(Phase.HANDLE_ORDER);
  }, []);

  useEffect(() => {
    if (phase !== Phase.PRE_CHECK) {
      return;
    }
    if (referrerSessionId && consentGiven) {
      sendTrackingData({
        referrerSid: referrerSessionId,
        referrerUrl,
        sourceSid: paywallSessionId,
        masterId: user.masterId,
        articleURL,
        sourceUrl,
      });
    }
    if (articleURL) {
      rawOrder!.articleURL = user?.userType === UserType.CONFIRMED ? addQueryParametersToUrl({
        url: articleURL,
        params: asteOauthParamsByArticleUrl(articleURL),
      }) : articleURL;
      setRawOrder(rawOrder!);
    }
    // Add params for SSO handling at article end if user is confirmed
    if (
      user?.subscriptions
      && hasExistingSubscription(user.subscriptions, offer)
    ) {
      setError({
        errorMessage: (
          <div className="error-existing-subscription">
            <p className="error-title">
              {t.YOU_ALREADY_HAVE_AN_EXISTING_SUBSCRIPTION}
            </p>
            {t.YOU_CAN_READ_CONTENT}
            {' '}
            <a href={rawOrder?.articleURL}>{t.FROM_HERE}</a>
            .
          </div>
        ) as ReactElement,
      });
    }
    setPhase(Phase.CONTACT_AND_PAYMENT);
  }, [user, phase]);

  return (
    <div className="Page">
      <div className="page-container">
        <div className="content-wrapper">{contentByPhase()}</div>
        {Phase.CONTACT_AND_PAYMENT === phase && (
          <TermsAndConditions offer={offer} />
        )}
      </div>
      {
        /* Testing helper button -> */
        IS_DEV && (
          <button
            className="go-back-button"
            type="button"
            onClick={() => {
              clearSession();
              window.location.href = '/dev';
            }}
          >
            Development popup
          </button>
        )
        /* <- Testing helper button */
      }
    </div>
  );
};

export default Page;
