import { ChangeEvent, ComponentType, ReactElement } from 'react';

/* eslint-disable react/require-default-props */
type InputProps = {
  id?: string;
  label?: string | ReactElement;
  value?: string;
  name?: string;
  type?: string;
  required?: boolean | undefined;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  inputClassName?: string;
  errorMessage?: string;
  disabled?: boolean;
};

const Input: ComponentType<InputProps> = ({
  id,
  label,
  value,
  name,
  type,
  required,
  onChange,
  onBlur,
  placeholder,
  inputClassName,
  errorMessage,
  disabled,
}: InputProps): ReactElement => (
  <label className={`label ${disabled ? 'disabled-label' : ''}`} htmlFor={name}>
    {`${label}${required ? '*' : ''}`}
    <input
      className={`input ${inputClassName} ${errorMessage?.length ? 'invalid' : ''} ${disabled ? 'disabled-input' : ''}`}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      type={type}
      id={id}
      name={name}
      disabled={disabled}
    />
    <span className="error-msg">{errorMessage}</span>
  </label>
);

export default Input;
