import React, { ComponentType, ReactElement, useContext } from 'react';
import { useRouteError } from 'react-router';
import { PageContext } from '../common/context';
import t from '../locales/fi';
import Header from './Header';
import ProgressBar from './ProgressBar';
import Session from '../common/session';
import { api } from '../common/api';
import { ERROR_LOG_ENDPOINT } from '../common/constants';

interface ErrorPageProps {
  children?: ReactElement | ReactElement[] | string;
  withHeader?: boolean;
  endSession?: boolean;
}

const defaultErrorPageProps: ErrorPageProps = {
  children: (
    <>
      <div className="error-string">{t.SOMETHING_WENT_WRONG}</div>
      <div className="error-redirect">
        {t.CONTACT_US}
        <a href="https://otavamedia.fi/asiakaspalvelu/#asiakaspalvelun-yhteystiedot">
          {t.CUSTOMER_SERVICE}
        </a>
      </div>
    </>
  ),
  withHeader: false,
  endSession: true,
};

const Container: ComponentType<any> = ({ content }: any): ReactElement => (
  <div className="page-container">
    <div className="error-page-wrapper">{content}</div>
  </div>
);
const ErrorPage: ComponentType<ErrorPageProps> = ({
  children,
  withHeader,
  endSession,
}: ErrorPageProps): ReactElement => {
  const routeError = useRouteError();

  if (routeError) {
    try {
      api(ERROR_LOG_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify({
          payload: {
            error: (routeError as any).stack || routeError,
            offer: sessionStorage.get('offer'),
            session: sessionStorage.get('session'),
          },
        }),
      });
      // eslint-disable-next-line no-empty
    } catch {}
  }
  let content;
  if (withHeader) {
    const { offer } = useContext(PageContext);
    const { packageId } = offer?.magazines?.[0] || {};
    content = (
      <div>
        <Header packageId={packageId} />
        <ProgressBar />
        <Container content={children} />
      </div>
    );
  } else {
    content = <Container content={children} />;
  }

  if (endSession) {
    Session.clearStore();
  }
  return content;
};
ErrorPage.defaultProps = defaultErrorPageProps;

export default ErrorPage;
