import {
  ComponentType,
  useMemo,
  useState,
} from 'react';
import { OrderContext } from '../context';
import { InitializedOrder } from '../models/InitializedOrder';
import { RawOrder } from '../models/RawOrder';
import { GenericProviderProps } from '../models/types';

const OrderProvider: ComponentType<GenericProviderProps> = ({ children }: GenericProviderProps) => {
  const [rawOrder, setRawOrder] = useState<RawOrder>({} as RawOrder);
  const [initializedOrder, setInitializedOrder] = useState<InitializedOrder>();
  const [finishedOrderResponse, setFinishedOrderResponse] = useState();

  const value = useMemo(
    () => ({
      rawOrder,
      setRawOrder,
      initializedOrder,
      setInitializedOrder,
      finishedOrderResponse,
      setFinishedOrderResponse,
    }),
    [rawOrder, initializedOrder, finishedOrderResponse],
  );

  return (
    <OrderContext.Provider value={value}>
      {children}
    </OrderContext.Provider>
  );
};

export default OrderProvider;
