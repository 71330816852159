/* eslint-disable react/jsx-props-no-spreading */
import { ComponentType, useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import Page from './Page';
import {
  AuthContext,
  PageContext,
  ToasterContext,
} from '../common/context';
import Spinner from '../components/Spinner';
import Header from '../components/Header';
import Toaster from '../components/Toaster';
import InitApp from './InitApp';
import Callback from './Auth/Callback';
import { Offer } from '../common/models/Offer';
import { User } from '../common/models/User';
import DevelopmentHelper from './Development/Index';
import Login from './Auth/Login';
import Logout from './Auth/Logout';
import ProgressBar from '../components/ProgressBar';
import { IS_DEV } from '../common/constants';
import ErrorPage from '../components/ErrorPage';

const hasOfferQueryParams = () => {
  const [params] = useSearchParams();
  return !!(
    window.location.search
    && params.get('offerId')
    && params.get('offeringId')
  );
};

const actionByData = (offer: Offer | undefined | null, user: User | undefined) => {
  if (!offer || hasOfferQueryParams()) return <InitApp />;
  if (!user) return <Navigate to="/login" />;
  return <Navigate to="/tilaa" />;
};

const Router: ComponentType = () => {
  const { offer, error } = useContext(PageContext);
  const { user } = useContext(AuthContext);
  const { toasterVisible } = useContext(ToasterContext);
  return (
    <div>
      <Header
        packageId={offer?.magazines ? offer.magazines[0].packageId : undefined}
      />
      {toasterVisible && <Toaster />}
      <ProgressBar />
      {!error ? (
        <Routes>
          <Route
            path="/login"
            errorElement={<ErrorPage />}
            element={<Login />}
          />
          <Route
            path="/logout/*"
            errorElement={<ErrorPage />}
            element={<Logout />}
          />
          <Route
            path="/auth_callback"
            errorElement={<ErrorPage />}
            element={!user ? <Callback /> : <Navigate to="/tilaa" />}
          />
          {IS_DEV && <Route path="/dev/*" element={<DevelopmentHelper />} />}
          <Route
            path="/tilaa"
            errorElement={<ErrorPage />}
            element={<Page offer={offer} user={user} />}
          />
          <Route
            path="/order/*"
            errorElement={<ErrorPage />}
            element={<Page offer={offer} user={user} />}
          />
          <Route
            path="/*"
            errorElement={<ErrorPage />}
            element={actionByData(offer, user)}
          />
        </Routes>
      ) : (
        <ErrorPage>{error?.errorMessage}</ErrorPage>
      )}
      {!error && (!offer || !user) && <Spinner />}
    </div>
  );
};
export default Router;
