/* eslint-disable import/prefer-default-export */
/* eslint-disable no-case-declarations */
/* eslint-disable no-underscore-dangle */
import Item from '../models/gtm/Item';
import Transaction from '../models/gtm/Transaction';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export enum GtmEvent {
  BEGIN_CHECKOUT = 'begin_checkout',
  PURCHASE = 'purchase',
}

const pushGtmEvent = (event: GtmEvent, _gtmObject: object): void => {
  const gtmObject = {
    event,
    ecommerce: {
      ..._gtmObject,
    },
  };
  window.dataLayer.push(gtmObject);
};

export const buildAndPushGtmEventObj = (params: { event: GtmEvent; gtmObj: Item[] | Transaction }): void => {
  const { event, gtmObj } = params;
  switch (event) {
    case GtmEvent.BEGIN_CHECKOUT:
      const _gtmObj = {
        items: gtmObj,
      };
      pushGtmEvent(event, _gtmObj);
      break;
    case GtmEvent.PURCHASE:
      pushGtmEvent(event, gtmObj);
      break;
    default:
      break;
  }
};
