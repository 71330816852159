/* eslint-disable react/require-default-props */
import {
  ComponentType,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ToasterContext } from '../common/context';
import WarningIcon from '../media/error-warning.svg';
import CloseIcon from '../media/close.svg';

const Toaster: ComponentType = (): ReactElement | null => {
  const { visible, content, hideToaster } = useContext(ToasterContext);
  const [animation, setAnimation] = useState('');

  useEffect(() => {
    if (visible) {
      setAnimation('fade-in');
    } else {
      setAnimation('fade-out');
    }
  }, [visible]);

  const onClose = () => {
    hideToaster();
  };

  return (
    <div className={`toaster-container ${animation}`}>
      <div className="icon-text-pair">
        <img src={WarningIcon} className="warning-icon" alt="Warning icon" />
        {content}
      </div>
      <button onClick={onClose} className="close-button" type="button">
        <img src={CloseIcon} alt="Close" />
      </button>
    </div>
  );
};

export default Toaster;
