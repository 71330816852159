export const {
  REACT_APP_BACKEND_ENDPOINT: BACKEND_ENDPOINT,
  REACT_APP_OMAUTH_CLIENT_ID: OMAUTH_CLIENT_ID,
  REACT_APP_OMAUTH_BASE_URL: OMAUTH_BASE_URL,
  REACT_APP_SENTRY_DSN: SENTRY_DSN,
  REACT_APP_SENTRY_ENABLED: SENTRY_ENABLED,
} = process.env;

export const CHECKOUT_ADD_CARD_URL = 'https://services.paytrail.com/tokenization/addcard-form';

export const SENTRY_ENDPOINT = `${BACKEND_ENDPOINT}/sentry`;

// Campaign page eapi endpoints
export const INVOICE_ORDER_INIT_ENDPOINT = 'payment/invoice';
export const CREDITCARD_ORDER_INIT_ENDPOINT = 'payment/creditcard';
export const CREDITCARD_CHARGE_ENDPOINT = 'payment/creditcard/charge';
export const ORDER_CANCEL_ENDPOINT = 'subscription/cancel';
export const ORDER_SUBSCRIBE_ENDPOINT = 'subscription/subscribe';
export const TRACKING_ENDPOINT = 'subscription/tracking';

export const AUTH_TOKEN_ENDPOINT = 'auth/token';
export const AUTH_USER_ENDPOINT = 'auth/user';
export const LOGOUT_ENDPOINT = 'auth/logout';
export const ERROR_LOG_ENDPOINT = 'log/error';
export const DECRYPT_TOKEN_ENDPOINT = 'auth/token/decrypt';

export const OTAVAMEDIA_URL = 'https://otavamedia.fi/';

// AUTH
export const OMAUTH_AUTH_LOGIN_ENDPOINT = 'authorize';
export const AUTH_CALLBACK = `${window.location.origin}/auth_callback`;

export const TABLET_MIN_WIDTH = 769;

export const IS_DEV = process.env.NODE_ENV === 'development';

export const MOCK_AUTH_PARAMS = 'mockAuthParams';

export const DEFAULT_APPNAME = 'openid';

// Magazine specific consts
export const RAKENNUSMAAILMA_PROD_URL = 'rakennusmaailma.fi';
export const RAKENNUSMAAILMA_DEV_URL = 'rakennusmaailma.asteaws.dev';
export const RAKENNUSMAAILMA_APPNAME = 'openid2';

export const ErrorCodes = {
  E_API_FETCH_ERROR: 'E_API_FETCH_ERROR',
  E_IDENT_ERROR: 'E_IDENT_ERROR',
  E_IDENT_NAMES_MISMATCH: 'E_IDENT_NAMES_MISMATCH',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  CARD_VERIFICATION_FAILED: 'CARD_VERIFICATION_FAILED',
  CARD_TOKEN_FETCH_FAILED: 'CARD_TOKEN_FETCH_FAILED',
  CARD_CHARGE_FAILED: 'CARD_CHARGE_FAILED',
  OFFER_PARAMS_MISSING: 'OFFER_PARAMS_MISSING',
  ORDER_NOT_FOUND: 'ORDER_NOT_FOUND',
  SUBSCRIBE_PARAMS_MISSING: 'SUBSCRIBE_PARAMS_MISSING',
  WRONG_PAYMENT_METHOD: 'WRONG_PAYMENT_METHOD',
};
